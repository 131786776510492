#about_container {
  background-color: #0b0808;
  padding: 17rem 2.8rem 0 2.8rem;
}

#about_container .container {
  flex-direction: column;
}

#about_container .heading-container {
  flex-direction: column;
  background-color: rgba(67, 67, 67, 0.067);
  z-index: 6;
  color: var(--color-beige);
  padding: 17rem 2.8rem;
}

.about-copy {
  background-color: rgba(67, 67, 67, 0.067);
  z-index: 6;
  padding: 59px;
}

@media screen and (max-width: 2560px) {
  #about_container .container {
    padding: 17rem 2.8rem;
  }
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1024px) {
  #about_container {
    padding: 2% 3%;
    width: 100%;
  }
  #about_container .heading-container {
    padding: 5rem 2.8rem;
  }
}
@media screen and (max-width: 768px) {
  #about_container .heading-container {
    margin-bottom: 3.6rem;
  }
}
@media screen and (max-width: 425px) {
  #about_container {
    padding: 1% 2%;
    width: 100%;
  }
  #about_container .heading-container {
    padding: 5rem 2.8rem;
  }
}

#hero_container {
  background-color: #0b0808;
}

#hero_container .container {
  display: flex;
}

.hero-copy {
  z-index: 6;
}

.hero-copy h5 {
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  width: 150%;
  font-weight: 700;
}

.hero-stars svg {
  height: 50px;
  width: 50px;
  fill: #e8bc3a;
}

#hero-jacket {
  top: 0%;
  right: -30%;
}
.hero-image {
  width: 68%;
  position: absolute;
  z-index: 3;
}

.hero-cta {
  padding-top: 10px;
}

.btn-hero {
  margin: 0 3.6rem 0 0;
  padding: 1.6rem 2.4rem;
  background-color: var(--color-red);
  color: var(--color-white);
  font-size: 2rem;
  letter-spacing: 1px;
  border-radius: 12px;
  font-family: var(--font-sans-serif);
  /* text-transform: uppercase; */
}

@media screen and (max-width: 2560px) {
  #hero_container .container {
    padding: 15rem 0 7.5rem 0;
  }

  .hero-heading {
    font-family: var(--font-sans-serif);
    line-height: 76%;
    margin: 1.6rem 0 2.4rem 0;
    letter-spacing: -13.4px;
    font-size: 18rem;
    padding-bottom: 2.4rem;
    width: 80%;
    color: var(--color-beige);
  }
  .hero-subheading {
    font-family: var(--font-serif);
    font-size: 2.4rem;
    width: 55%;
    line-height: 145%;
    color: var(--color-white);
  }
  .hero-stars {
    margin: 2.4rem 0;
  }
  .hero-copy h3 {
    font-family: var(--font-serif);
    margin-bottom: 2.4rem;
    font-size: 2.4rem;
    width: 100%;
    font-weight: 700;
    color: var(--color-white);
  }
}
@media screen and (max-width: 1920px) {
  .hero-heading {
    font-size: 16rem;
  }
}
@media screen and (max-width: 1440px) {
  #hero_container {
    padding: 2% 3%;
    width: 100%;
  }
  #hero_container .container {
    padding: 100px 0 75px 0;
  }
  .hero-heading {
    font-size: 12rem;
    width: 75%;
    letter-spacing: -4.4px;
  }
  .hero-subheading {
    width: 60%;
  }
}

@media screen and (max-width: 1024px) {
  #hero_container {
    padding: 2% 3%;
    width: 100%;
  }
  .hero-heading {
    font-size: 12rem;
    /* max-width: 70%; */
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .hero-heading {
    font-size: 9.6rem;
    letter-spacing: -3.4px;
  }
  .hero-subheading {
    font-size: 2.6rem;
    width: 70%;
    background-color: rgba(67, 67, 67, 0.4);
    padding: 1.6rem 1.2rem;
  }

  #hero-jacket {
    top: 10%;
    right: -4%;
  }

  .hero-image {
    width: 56%;
  }
}

@media screen and (max-width: 425px) {
  #hero_container {
    padding: 1% 2%;
    width: 100%;
  }
  .hero-heading {
    font-size: 6.4rem;
    letter-spacing: -1.4px;
    width: 100%;
  }

  .hero-subheading {
    margin-bottom: 3.2rem;
    font-size: 2.2rem;
    line-height: 120%;
    width: 100%;
    text-align: center;
  }

  #hero-jacket {
    top: 61%;
    right: 9%;
  }

  .hero-image {
    width: 80%;
  }

  .hero-stars-copy {
    background-color: #424242c4;
    padding: 1.2rem 0.6rem;
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }

  .hero-copy h3 {
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    width: 100%;
    font-weight: 700;
    text-align: center;
  }

  .hero-stars {
    display: flex;
    justify-content: center;
  }

  .hero-stars svg {
    height: 30px;
    width: 30px;
    fill: #e8bc3a;
    margin: 0 0.3rem;
  }

  .flex-40 {
    max-width: 0;
  }

  .flex-100 {
    width: 100%;
    max-width: 100%;
  }

  .hero-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btn-hero {
    margin: 1rem 0 0 0;
  }
}

.container {
  position: relative;
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 1.2rem;
}

.image-container {
  max-width: 100%;
}

.copy-container {
  background-color: rgba(67, 67, 67, 0.067);
  z-index: 9;
  font-size: 2.4rem;
  color: var(--color-beige);
  padding: 17rem 2.8rem;
}

.hidden {
  visibility: hidden;
}

.heading-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.title-text {
  font-family: var(--font-serif);
}

.flex-copy-heading {
  margin: 2rem 0 3rem 0;
  line-height: 0.85;
  font-family: var(--font-sans-serif);
}
.flex-copy-text {
  font-family: var(--font-serif);
  font-size: 2.8rem;
  line-height: 125%;
  color: var(--color-white);
}

.text-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
}

.flex-50 {
  max-width: 50%;
}

@media screen and (max-width: 2560px) {
  .title-text {
    font-size: 3.2rem;
  }
  .flex-copy-heading {
    font-size: 7.8rem;
    letter-spacing: -6.4px;
  }
  .flex-copy-text {
    font-family: var(--font-serif);
    line-height: 125%;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
  .flex-copy-heading {
    font-size: 5.6rem;
    letter-spacing: -3.4px;
  }
  .flex-copy-text {
    font-size: 2.4rem;
  }
  .copy-container {
    padding: 7rem 2.8rem;
  }
}
@media screen and (max-width: 768px) {
  .flex-copy-heading {
    font-size: 4.8rem;
    letter-spacing: -0.4px;
  }
  .flex-copy-text {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 425px) {
  .container {
    position: relative;
    grid-template-columns: 1fr;
  }

  .copy-container {
    padding: 5rem 0.8rem;
  }

  .heading-container {
    padding: 25px;
  }

  .title-text {
    font-size: 1.6rem;
  }
  .flex-copy-heading {
    font-size: 3.6rem;
    letter-spacing: -0.4px;
  }
  .flex-copy-text {
    font-size: 1.8rem;
  }
}

.masonry-layout {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 155px 0 0 0;
  justify-content: center;
  position: relative;
}

.masonry-item {
  max-width: 33%;
}

.btn {
  display: block;
  margin: 100px auto;
  padding: 24px 48px;
  background-color: var(--color-red);
  color: var(--color-white);
  font-size: 2.4rem;
  border-radius: 12px;
}

@media screen and (max-width: 2560px) {
  .masonry-layout {
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
  .btn {
    position: static;
  }
}
@media screen and (max-width: 768px) {
  .masonry-item {
    max-width: 45%;
  }
  .btn {
    margin: 50px auto;
    padding: 12px 24px;
    font-size: 1.6rem;
    position: absolute;
    bottom: 0;
  }
}
@media screen and (max-width: 425px) {
  .masonry-item {
    max-width: 95%;
  }
  .btn {
    margin: 50px auto;
    padding: 12px 24px;
    font-size: 1.6rem;
  }
}

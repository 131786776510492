#delivery_container {
  background-color: #0b0808;
  padding: 17rem 2.8rem 0 2.8rem;
}

.delivery-img {
  width: 100%;
  position: absolute;
  left: -54%;
  top: 16%;
}

.delivery-copy {
  background-color: rgba(67, 67, 67, 0.067);
  z-index: 6;
  color: var(--color-beige);
}

#door {
  top: 20%;
  left: -0%;
  width: 500px;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1024px) {
  #delivery_container {
    width: 100%;
  }
  #delivery_container .container {
    display: grid;
  }
}
@media screen and (max-width: 768px) {
  #delivery_container .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
  }

  .delivery-copy {
    max-width: 50%;
  }
  /* 
  .delivery-order-img {
    order: 2;
  } */

  .delivery-order-copy {
    order: 2;
  }

  /* .delivery-img {
    width: 167%;
    left: -164%;
    top: 34%;
  } */

  #door {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  #delivery_container {
    width: 100%;
    padding: 5rem 2.8rem;
  }

  #delivery_container .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  #door {
    position: relative;
    width: 360px;
    left: -2%;
  }

  .delivery-copy {
    max-width: 100%;
  }

  .delivery-order-img {
    order: 2;
  }

  .delivery-order-copy {
    order: 1;
  }

  /* .delivery-img {
    width: 114%;
    left: -34%;
    bottom: -23%;
    top: 88%;
  } */
}

@media screen and (max-width: 320px) {
  #door {
    left: -12%;
    width: 300px;
  }
}

#reviews_container {
  background-color: #0b0808;
  position: relative;
  padding-top: 155px;
}

#reviews_container .container {
  flex-direction: column;
}

#reviews_container .heading-container {
  /* New */
  background-color: rgba(67, 67, 67, 0.067);
  z-index: 6;
  padding: 59px;
  color: var(--color-beige);
}

#reviews_container .flex-copy-heading {
  color: var(--color-white);
}

.reviews-container {
  position: absolute;
  top: 12%;
  left: 0;
  width: 102%;
}

.review-stars {
  margin: 3.2rem 0;
}
.review-stars svg {
  height: 50px;
  width: 50px;
  fill: #e8bc3a;
}

/* .card-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
  margin: auto;
  padding: 155px 0 0 0;
} */

@media screen and (max-width: 2560px) {
  #reviews_container .flex-copy-text {
    padding: 12px;
  }
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
  #reviews_container .heading-container {
    padding: 105px 0 155px 0;
  }
}
@media screen and (max-width: 1024px) {
  #reviews_container {
    padding: 2% 3%;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #reviews_container .heading-container {
    padding: 50px 0 0 0;
  }
}
@media screen and (max-width: 425px) {
  #reviews_container {
    padding: 1% 2%;
    width: 100%;
  }
  .reviews-container {
    top: 26%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1.2rem;
  border: 1px solid black;
  justify-content: space-around;
  background-color: rgba(18, 18, 18, 0.688);
  z-index: 6;
  padding: 3.6rem 2.4rem 2.4rem 2.4rem;
  color: var(--color-white);
}

.social-container {
  display: flex;
  justify-content: space-between;
}

.social-heading {
  display: inline-block;
}

.social-span {
  display: inline-block;
}

.social-span svg {
  height: 30px;
  width: 30px;
}

.card h2,
.card h5,
.review-star {
  margin-bottom: 1.6rem;
}

.card h5 {
  line-height: 135%;
  font-size: 2.4rem;
}

.review-star svg {
  height: 33px;
  width: 33px;
  fill: #e8bc3a;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 425px) {
  .card h2 {
    font-size: 3.6rem;
  }
  .card h5 {
    line-height: 125%;
    font-size: 1.6rem;
  }
}

#footer__section {
  min-height: 30vh;
  /* background-color: #0b0808; */
}

.footer-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
  /* padding-top: 155px; */
  color: var(--color-white);
}

.app__footer {
  width: 100%;
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  background-color: #0b0808;
  padding-top: 0;
  padding-bottom: 9rem;
}

.app__footer-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
  flex: 1;
  margin: 1rem;
  text-align: center;
}

.app__footer-links_logo img:first-child {
  width: 210px;
  margin-bottom: 0.75rem;
}

.app__footer-links_work p:nth-child(2n + 1) {
  margin-bottom: 1rem;
}

.app__footer-links_icons {
  margin-top: 0.5rem;
}

.app__footer-links_icons svg {
  color: var(--color-beige);
  margin: 0.5rem;
  font-size: 4.8rem;
  cursor: pointer;
  margin: 0 2.4rem;
}

.app__footer-links_icons svg:hover {
  color: var(--color-red);
}

.app__footer-headtext {
  font-family: var(--font-base);
  color: var(--color-beige);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 32px;
  line-height: 41.6px;
  margin-bottom: 1rem;
}

.app__footer-links_contact h3,
.app__footer-links_work h3 {
  margin-bottom: 0.8rem;
  font-size: 2.4rem;
}

.footer__copyright {
  margin-top: 3rem;
  padding: 0 48px;
  text-align: center;
  color: var(--color-white);
}

.footer__copyright h4,
.h4__sans-serif {
  font-size: 1.6rem;
}

#phone-number {
  color: var(--color-white);
  font-size: 2.4rem;
}
#phone-number:hover {
  color: var(--color-red);
  font-size: 2.4rem;
}

#email {
  color: var(--color-white);
}

#email:hover {
  color: var(--color-red);
}
@media screen and (max-width: 2560px) {
}

@media screen and (max-width: 1920px) {
  .app__footer-headtext {
    font-size: 51px;
    line-height: 61.6px;
  }
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1024px) {
  .app__footer-links {
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  .app__footer-links_contact,
  .app__footer-links_logo,
  .app__footer-links_work {
    margin: 2rem 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .app__footer {
    padding: 0 0 2rem 0;
  }
}

@media screen and (max-width: 425px) {
  .app__footer-links_logo img:first-child {
    width: 80%;
  }

  .app__footer-headtext {
    font-size: 32px;
  }

  #email {
    font-size: 2rem;
  }
}

.hiddenAria {
  visibility: hidden;
  display: none;
}

#alterations_container {
  background-color: #0b0808;
  position: relative;
  padding: 17rem 2.8rem 0 2.8rem;
}

#alterations-1 {
  padding: 5rem 2.8rem;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
  #alterations_container {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #alterations_container {
    padding: 7rem 2.8rem;
  }

  #alterations-1 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #alterations_container .container {
    text-align: left;
  }

  .alterations-order-copy {
    z-index: 9;
    width: 90%;
  }
}
@media screen and (max-width: 425px) {
  #alterations_container {
    width: 100%;
    padding: 5rem 2.8rem;
  }

  #alterations_container .container {
    text-align: center;
  }
  .alterations-copy {
    z-index: 3;
    width: 100%;
  }
}

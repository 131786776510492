.navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  padding: 2% 3%;
  position: fixed;
  z-index: 100;
}

.colorChange {
  background-color: #0b0808;
}

.navbar__list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__logo {
  display: block;
  text-align: center;
}

.navbar__logo img {
  width: 150px;
}

.navbar__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.navbar__links {
  font-family: var(--font-sans-serif);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 30px;
}

.navbar__links a {
  font-size: 2.4rem;
  margin: 0 1rem;
  padding: 0 1.6rem;
  cursor: pointer;
  color: var(--color-white);
}

.navbar__links a:hover {
  color: var(--color-red);
}

/* ============ */
/* Social Icons */

.hero__social-list,
.hero__social-list-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__social-links,
.hero__social-links-mobile {
  font-family: var(--font-sans-serif);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 30px;
  font-size: var(--h4);
}

.hero__social-links a,
.hero__social-links-mobile a {
  margin: 0 1rem;
  padding: 0 1.6rem;
  cursor: pointer;
  color: var(--color-light-beige);
}

.hero__social-links a:hover,
.hero__social-links-mobile a:hover {
  color: var(--color-red);
}

.hero__social-links a svg,
.hero__social-links-mobile a svg {
  height: 50px;
  width: 50px;
}

/* ============== */
/* Mobile Version */
/* ============== */

.navbar__mobile {
  display: none;
}

.navbar__mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.navbar__mobile-overlay .overlay__close {
  font-size: 32px;
  color: var(--color-light-beige);
  cursor: pointer;
  position: absolute;
  top: 7%;
  right: 4%;
}

.navbar__mobile-links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-light-beige);
  font-size: 2.4rem;
  text-align: center;
  font-family: var(--font-sans-serif);
}

.navbar__mobile-links li:hover {
  color: var(--color-red);
}

.navbar__mobile-links li a {
  color: var(--color-light-beige);
}

#navbar__parent {
  position: relative;
}

.navbar__social {
  position: absolute;
  bottom: 5%;
  left: 46%;
}

@media screen and (max-width: 2560px) {
  .navbar__links a {
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 1920px) {
  .navbar__links a {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 1440px) {
  .hero__social-links a svg,
  .hero__social-links-mobile a svg {
    height: 30px;
    width: 30px;
    color: var(--color-beige);
  }

  .hero__social-links a {
    margin: 0;
  }
}
@media screen and (max-width: 1024px) {
  .navbar {
    justify-content: space-between;
  }
  .navbar__list {
    display: none;
  }
  .navbar__mobile {
    display: flex;
  }
  .navbar__mobile svg {
    height: 50px;
    width: 50px;
  }
  .navbar__logo {
    position: static;
  }
  .hero__social-list {
    display: none;
  }
  .navbar__mobile-overlay {
    padding: 10rem 0 5rem 0;
  }
  .navbar__mobile-links {
    padding-bottom: 75px;
  }
}
@media screen and (max-width: 768px) {
  .navbar__logo img {
    width: 100px;
  }
  .navbar__mobile-overlay .overlay__close {
    top: 4%;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .navbar {
    padding: 2% 3%;
  }
  .hero__social-list {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .navbar {
    padding: 2% 3%;
  }
  .navbar__logo img {
    width: 75px;
    padding-top: 5px;
  }
  .navbar__mobile-overlay .overlay__close {
    top: 2%;
    right: 2%;
  }

  .navbar__mobile-overlay {
    padding: 10rem 0 5rem 0;
  }
  .hero__social-links a,
  .hero__social-links-mobile a {
    margin: 0 2.4rem;
    padding: 0;
    cursor: pointer;
    color: var(--color-white);
  }
  .navbar__mobile-links {
    padding-bottom: 75px;
  }
}

:root {
  --font-serif: "Cardo", serif;
  --font-sans-serif: "Lato", sans-serif;

  --color-red: #a11d31;
  --color-light-beige: #d3c8b5;
  --color-beige: #d6be93;
  --color-black: #100e0c;
  --color-white: #d1cdc5;

  --h64: 6.4rem;
  --h48: 4.8rem;
  --h32: 3.2rem;
  --h24: 2.4rem;
  --h18: 1.8rem;
  --h16: 1.6rem;
  --p16: 1.6rem;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  line-height: 1.2;
  overflow-x: hidden;
  font-size: 1.6rem;
}

main {
  overflow-x: hidden;
}

ul {
  list-style: none;
  list-style-type: none;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

a,
a:active,
a:hover,
a:visited,
a:link {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-weight: 400;
  letter-spacing: 0.5px;
}

h1 {
  font-size: var(--h64);
}

h2 {
  font-size: var(--h48);
}

h3 {
  font-size: var(--h32);
}

h4 {
  font-size: var(--h24);
}

h5 {
  font-size: var(--h18);
}

h6,
p,
li {
  font-size: var(--h16);
}

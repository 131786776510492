#services_container {
  background-color: #0b0808;
}

.services-heading {
  font-family: var(--font-sans-serif);
  font-size: 9.6rem;
  letter-spacing: -2.4px;
  line-height: 76%;
  color: var(--color-white);
}

.services-copy {
  font-size: 2.4rem;
  color: var(--color-beige);

  background-color: rgba(67, 67, 67, 0.067);
  z-index: 6;
}

.service-icons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 16rem 0 3rem 0;
}

.service-icons li {
  text-align: center;
  color: var(--color-white);
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  object-fit: contain;
}

.services-copy2 h3 {
  margin-bottom: 2.4rem;
}

.services-order-icons h2 {
  padding: 5rem 2.8rem;
}

@media screen and (max-width: 2560px) {
  #services_container {
    padding: 17rem 2.8rem 0 2.8rem;
  }
  #services_container .container {
    margin: auto;
  }
  #services_container .flex-container {
    align-items: center;
  }
  .container {
    flex-direction: row;
  }

  .img-container {
    margin-bottom: 20px;
  }

  .img-container img {
    height: 13rem;
    width: 13rem;
  }

  .service-icons li {
    width: 175px;
    padding: 1.2rem;
    font-size: 2.3rem;
  }
  .flex-50 {
    max-width: 50%;
  }
}
@media screen and (max-width: 1920px) {
  .title-text {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 1440px) {
  #services_container {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #services_container {
    background-color: #0b0808;
    padding: 7rem 2.8rem;
  }

  #services_container .container {
    text-align: left;
    display: grid;
  }

  .img-container img {
    height: 10rem;
    width: 10rem;
  }

  .service-icons {
    margin: 7rem 0 3rem 0;
  }

  .service-icons li {
    width: 150px;
    padding: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  #services_container .container {
    display: flex;
    flex-direction: column;
  }

  .services-order-icons {
    order: 2;
  }
  /* .services-order-copy {
    order: 2;
  } */
  .flex-container {
    gap: 10%;
  }
  .flex-50 {
    max-width: 0;
  }
  .flex-full {
    width: 100%;
    max-width: 100%;
  }

  .service-icons {
    max-width: 590px;
    margin: 5rem auto 2rem auto;
  }

  .service-icons li {
    width: 150px;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .flex-container {
    gap: 0;
  }
}

@media screen and (max-width: 425px) {
  #services_container {
    width: 100%;
    padding: 5rem 2.8rem;
  }

  #services_container .container {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .service-icons {
    margin: 3rem 0 3rem 0;
  }

  .services-order-icons {
    order: 2;
  }
  .services-order-copy {
    order: 1;
  }

  .img-container img {
    height: 7rem;
    width: 7rem;
  }
  .service-icons {
    max-width: 375px;
  }

  .services-copy {
    font-size: 2.4rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .service-icons li {
    width: 120px;
    font-size: 1.6rem;
  }
}
